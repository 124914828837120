<template>
  <div class="d-board">
    <div class="container accounts-container">
      <div class="mid-area list-box">
        <ul>
          <li v-if="!showLoader" class="users-grid">
            <div class="user-select">
              <k-select
                :options="sortedUsers"
                label-key="displayUserName"
                :multiple="false"
                :value="selectedUserId"
                :searchable="true"
                label="Please select user"
                @input="onUserSelect"
              />
            </div>
            <div class="user-action">
              <div v-if="hasPermission(E_USER, PRIVILEGE_TO_CREATE)" class="button-box flex">
                <div class="box px-2 text-white">
                  <span class="intip4" data-tool-tip="Add User">
                    <font-awesome-icon
                      :icon="['far', 'user-plus']"
                      @click="displayNewUserModal()"
                    />
                  </span>
                </div>
                <div v-if="!hideInvite" class="box px-2 text-white">
                  <k-button
                    :size="4"
                    label="Invite User(s)"
                    @click="displayInviteUserModal"
                    @close="onCloseInviteUserModal"
                  />
                </div>
              </div>
            </div>
          </li>
          <li v-if="userDetails && !showLoader">
            <div v-if="msg" class="msg">{{ msg }}</div>
            <table class="detail-table">
              <thead>
                <th class="com-title">Name</th>
                <th class="com-title">Email</th>
                <th class="com-title">Role</th>
                <th class="com-title">Organization</th>
                <th class="com-title">Invite Accepted</th>
                <th class="com-title"></th>
              </thead>
              <tbody>
                <tr>
                  <td class="sub-li">
                    <span v-if="userDetails && userDetails.name">
                      {{ userDetails.name }}
                    </span>
                    <span v-else>--</span>
                  </td>
                  <td class="sub-li">
                    <span v-if="userDetails && userDetails.emailAddress">
                      {{ userDetails.emailAddress }}
                    </span>
                    <span v-else>--</span>
                  </td>
                  <td class="sub-li">
                    <span v-if="userDetails && userDetails.role">
                      {{ userRole }}
                    </span>
                    <span v-else>--</span>
                  </td>
                  <td class="sub-li">
                    <span v-if="userDetails && userDetails.organization">
                      {{ userDetails.organization.name }}
                    </span>
                    <span v-else>--</span>
                  </td>
                  <td class="sub-li ">{{ selectedUser.inviteAccepted ? '✓' : '𐄂' }}</td>
                  <td class="sub-li" style="width:50px">
                    <div class="button-box sub-li-item">
                      <div v-if="hasPermission(E_USER, PRIVILEGE_TO_CREATE)" class="box px-2">
                        <span class="intip4" data-tool-tip="Edit User">
                          <font-awesome-icon
                            :icon="['fas', 'pencil']"
                            @click="displayEditUserModal()"
                          />
                        </span>
                      </div>
                      <div
                        v-if="
                          hasPermission(E_USER, PRIVILEGE_TO_CREATE) &&
                            (selectedUserRole === 'EXTERNAL_ADMIN' ||
                              selectedUserRole === 'EXTERNAL_SUB_ADMIN')
                        "
                        class="box px-2"
                      >
                        <span class="intip4" data-tool-tip="Add Account">
                          <font-awesome-icon :icon="['fas', 'plus']" @click="addAccount()" />
                        </span>
                      </div>
                      <div v-if="!selectedUser.inviteAccepted" class="box px-2">
                        <span class="intip4" data-tool-tip="Re-Invite User">
                          <font-awesome-icon
                            :icon="['fas', 'envelope']"
                            @click="reInviteUser(selectedUser)"
                          />
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
          <li v-if="showLoader">
            <b-loading-spinner class="txt-center" />
          </li>
          <li v-if="userDetails">
            <div>
              <p class="sm">List of Accounts and Advertisers that this user has access to</p>
            </div>
            <table class="detail-table">
              <thead>
                <th class="com-title">Account Name</th>
                <th class="com-title">Advertiser Name</th>
                <th class="com-title">
                  <div v-if="isInternalUserRole || isExternalUserRole" class="box px-2 text-white">
                    <span class="intip4" data-tool-tip="Add Advertiser">
                      <font-awesome-icon :icon="['far', 'plus']" @click="addAdvertiser" />
                    </span>
                  </div>
                </th>
              </thead>
              <tbody
                v-if="isInternalAdminRole || isInternalSubAdminRole"
                class="account-advertiser-list"
              >
                <tr>
                  <td class="sub-li">ALL</td>
                  <td class="sub-li">ALL</td>
                  <td class="sub-li"></td>
                </tr>
              </tbody>
              <tbody
                v-if="isExternalSubAdminRole || isExternalAdminRole"
                class="account-advertiser-list"
              >
                <tr v-for="account in userAccounts" :key="account.id">
                  <td class="sub-li">
                    <div class="sub-li-item">
                      <span v-if="account.name">
                        {{ account.name }}
                      </span>
                    </div>
                  </td>
                  <td class="sub-li">
                    <span>ALL</span>
                  </td>
                  <td class="sub-li-item">
                    <span class="px-2">
                      <span class="intip4" :data-tool-tip="`Delete Account: ${account.name}`">
                        <font-awesome-icon
                          :icon="['fas', 'trash']"
                          @click="onClickConfirm({ type: 'account', value: account })"
                        />
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody
                v-if="isInternalUserRole || isExternalUserRole"
                class="account-advertiser-list"
              >
                <div v-for="account in userAccounts" :key="account.id" class="accounts-grid">
                  <tr v-if="!hasAdvertisers(account.advertisers)">
                    <td class="sub-li">
                      <div class="sub-li-item">
                        <span v-if="account.name">
                          {{ account.name }}
                        </span>
                        <!-- <div class="sub-li-item">
                          <span class="px-2 text-white">
                            <a href="#">
                              <k-icon-tooltip
                                :icon="['far', 'plus']"
                                @click="addAdvertiser(account)"
                              />
                            </span>
                          </span>
                          <span class="px-2">
                            <span class="intip4" data-tool-tip="Delete Account">
                              <font-awesome-icon
                                :icon="['fas', 'trash']"
                                @click="onClickConfirm({ type: 'account', value: account })"
                              />
                            </span>
                          </span>
                        </div> -->
                      </div>
                    </td>
                    <td class="sub-li">
                      <span> NA </span>
                    </td>
                    <td class="sub-li"></td>
                  </tr>
                  <tr v-for="advertiser in sortedAdvertisers(account)" :key="advertiser.id">
                    <td class="sub-li">
                      <div class="sub-li-item">
                        <span v-if="account.name">
                          {{ account.name }}
                        </span>
                        <!-- <div class="sub-li-item">
                          <span class="px-2 text-white">
                            <a href="#">
                              <k-icon-tooltip
                                :icon="['far', 'plus']"
                                @click="addAdvertiser(account)"
                              />
                            </span>
                          </span>
                          <span class="px-2">
                            <span class="intip4" data-tool-tip="Delete Account">
                              <font-awesome-icon
                                :icon="['fas', 'trash']"
                                @click="onClickConfirm({ type: 'account', value: account })"
                              />
                            </span>
                          </span>
                        </div> -->
                      </div>
                    </td>
                    <td class="sub-li">
                      <div class="sub-li-item">
                        <span v-if="advertiser && advertiser.name">
                          {{ advertiser.name }}
                        </span>
                      </div>
                    </td>
                    <td class="sub-li-item li-last">
                      <span class="px-2">
                        <span
                          class="intip4"
                          :data-tool-tip="`Delete Advertiser: ${advertiser.name}`"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'trash']"
                            @click="onClickConfirm({ type: 'advertiser', value: advertiser })"
                          />
                        </span>
                      </span>
                    </td>
                  </tr>
                </div>
              </tbody>
            </table>
          </li>
        </ul>
      </div>
    </div>
    <add-edit-user
      v-if="showNewOrEditUserModal"
      :initial-user="user"
      :selected-user-accounts="
        createNewUser ? [] : externalUser ? userManagedAccounts : userAccounts
      "
      :all-roles="allRoles"
      :all-organizations="allOrganizations"
      @close="onCloseUserModal"
    />
    <invite-user
      v-if="inviteUserModal"
      :show-modal="inviteUserModal"
      @onClickCancel="onClickCancelInvite"
      @close="onCloseInviteUserModal"
    />
    <map-account
      v-if="addAccountModal"
      :internal-user="selectedUser"
      :mapped-accounts="mappedAccounts"
      @close="onCloseModal"
    />
    <map-advertiser
      v-if="addAdvertiserModal"
      :internal-user="selectedUser"
      :mapped-advertisers="mappedAdvertisers"
      @close="onCloseAdvertiserModal"
    />
    <div class="confirm-modal-users">
      <ConfirmModal
        v-if="showConfirmBoxModal"
        width="400px"
        :type="confirmationObj.type"
        @cancel="showConfirmBoxModal = false"
        @remove="onClickConfirmSubmit"
      />
      <ConfirmModalReInvite
        v-if="showConfirmBoxModalRI"
        width="495px"
        :email="confirmationReInviteObj.email"
        @cancel="showConfirmBoxModalRI = false"
        @send="onClickConfirmReInviteSubmit"
      />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import commonHelper, { isBlank } from 'adready-api/helpers/common';
import { PRIVILEGE_TO_CREATE, E_USER } from 'adready-api/auth-constant';
import accountApi from 'adready-api/api/account';
import usersApi from 'adready-api/api/user';
import { User } from 'adready-api/model/user';
import KSelect from 'adready-vue/components/elements/k-select.vue';
import MapAccount from '~/components/admin/map-account.vue';
import MapAdvertiser from '~/components/admin/map-advertiser.vue';
import forklift from '~/components/mixins/forklift-mixin';
import authzMixin from '~/components/mixins/authz-mixin';
import ConfirmModal from '~/pages/ConfirmModal.vue';
import ConfirmModalReInvite from '~/pages/ConfirmModalReInvite.vue';
import InviteUser from '~/components/admin/InviteUser.vue';

export default {
  name: 'Users',
  components: {
    KSelect,
    AddEditUser: () =>
      import(/* webpackChunkName: "add-edit-user" */ '~/components/admin/add-edit-user.vue'),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    MapAccount,
    MapAdvertiser,
    ConfirmModal,
    InviteUser,
    ConfirmModalReInvite,
  },
  mixins: [forklift, authzMixin],
  data() {
    return {
      user: new User(),
      showNewOrEditUserModal: false,
      createNewUser: false,
      selectedUserId: null,
      userAccounts: [],
      userManagedAccounts: [],
      externalUser: false,
      showConfirmBoxModal: false,
      addAccountModal: false,
      addAdvertiserModal: false,
      PRIVILEGE_TO_CREATE,
      E_USER,
      confirmationObj: null,
      showLoader: false,
      inviteUserModal: false,
      hideInvite: true,
      showConfirmBoxModalRI: false,
      confirmationReInviteObj: null,
      showMsg: null,
    };
  },
  computed: {
    allUsers: get('common/allUsers'),
    allOrganizations: get('common/allOrganizations'),
    currentUser: get('common/currentUser'),
    allRoles: get('common/allRoles'),
    sortedUsers() {
      if (isBlank(this.allUsers)) {
        return [];
      }

      const allowedUsers = this.allUsers.filter((u) => this.isAllowedToShow(u));
      allowedUsers.forEach((u) => {
        u.displayUserName = `${u.userName || ''} (${u.email})`;
      });

      const sortedUsers = commonHelper.caseInsensitiveSort(
        [].concat(allowedUsers),
        'displayUserName'
      );
      const su = JSON.parse(JSON.stringify(sortedUsers));
      su.forEach((item) => {
        item.name = item.displayUserName;
      });
      return su;
    },
    msg() {
      if (!isBlank(this.showMsg)) {
        return this.showMsg;
      }
      return null;
    },
    selectedUser() {
      if (isBlank(this.selectedUserId)) {
        return undefined;
      }
      return this.allUsers.find((item) => item.id === this.selectedUserId);
    },
    selectedUserRole() {
      if (isBlank(this.selectedUserId)) {
        return undefined;
      }
      return this.selectedUser.role.name;
    },
    mappedAccounts() {
      const mappedAccounts = this.userAccounts.map((u) => u.id);
      return mappedAccounts;
    },
    mappedAdvertisers() {
      const mappedAdvertisers = [];
      this.userAccounts.forEach((u) => {
        mappedAdvertisers.push(...u.advertisers.map((ua) => ua.id));
      });
      return mappedAdvertisers;
    },
    userDetails() {
      if (isBlank(this.selectedUserId)) {
        return undefined;
      }

      const userObj = this.allUsers.find((item) => item.id === this.selectedUserId);
      let organization = null;
      if (userObj?.organizationId && !isBlank(this.allOrganizations)) {
        organization = this.allOrganizations.find((item) => item.id === userObj.organizationId);
      }
      return {
        name: userObj.userName,
        emailAddress: userObj.email,
        role: userObj.role || '',
        organization,
      };
    },
    userRole() {
      if (this.userDetails && this.userDetails.role) {
        return this.userDetails.role.name;
      }
      return '';
    },
    isInternalAdminRole() {
      return this.userRole === 'INTERNAL_ADMIN';
    },
    isInternalSubAdminRole() {
      return this.userRole === 'INTERNAL_SUB_ADMIN';
    },
    isInternalUserRole() {
      return this.userRole === 'INTERNAL_USER';
    },
    isExternalSubAdminRole() {
      return this.userRole === 'EXTERNAL_SUB_ADMIN';
    },
    isExternalUserRole() {
      return this.userRole === 'EXTERNAL_USER';
    },
    isExternalAdminRole() {
      return this.userRole === 'EXTERNAL_ADMIN';
    },
  },
  watch: {
    selectedUserId() {
      this.getUserAccountAdvertiser(this.selectedUserId);
    },
    userRole() {
      this.getUserAccountAdvertiser(this.selectedUserId);
    },
  },
  mounted() {
    this.loadRoleMasters();
    this.loadAllOrganizations();
    this.loadAllUsers(true);
  },

  methods: {
    reInviteUser(user) {
      this.showMsg = null;
      this.onClickConfirmReInvite({
        email: user.email,
        value: user,
      });
    },
    onClickConfirmReInvite(item) {
      this.confirmationReInviteObj = item;
      this.showConfirmBoxModalRI = true;
    },
    onClickConfirmReInviteSubmit() {
      this.sendInvite(this.confirmationReInviteObj.value);
      this.showConfirmBoxModalRI = false;
    },
    async sendInvite(user) {
      try {
        // uncomment at the time of commit
        await usersApi.sendInvite(user.id);
        this.showMsg = 'Email invitation  sent.';
      } catch (err) {
        this.showMsg = 'Unable to send an invitation email.';
        console.error(`error sending invite to ${user.email} (id: ${user.id}) user ->`, err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    onClickCancelInvite() {
      this.inviteUserModal = false;
    },
    onCloseInviteUserModal() {
      this.inviteUserModal = false;
    },
    isAllowedToShow(user) {
      if (isBlank(user.role) || isBlank(user.role.name)) {
        return false;
      }
      return this.currentUser.role.canCreateRole.includes(user.role.name);
    },
    displayNewUserModal() {
      this.user = new User();
      this.createNewUser = true;
      this.showNewOrEditUserModal = true;
    },
    displayInviteUserModal() {
      this.inviteUserModal = true;
    },
    displayEditUserModal() {
      this.user = this.selectedUser;
      this.createNewUser = false;
      this.showNewOrEditUserModal = true;
    },
    addAccount() {
      this.addAccountModal = true;
    },
    onClickConfirm(item) {
      this.confirmationObj = item;
      this.showConfirmBoxModal = true;
    },
    onClickConfirmSubmit() {
      if (this.confirmationObj.type === 'account') {
        this.onDeleteAccount(this.confirmationObj.value);
      } else if (this.confirmationObj.type === 'advertiser') {
        this.onDeleteAdvertiser(this.confirmationObj.value);
      }
      this.showConfirmBoxModal = false;
    },
    onDeleteAccount(account) {
      const userAccount = { accountId: account.id, userId: this.selectedUserId };
      usersApi.removeAccount(this.selectedUserId, userAccount).then(() => {
        this.getUserAccountAdvertiser(this.selectedUserId);
        if (this.selectedUserId === this.currentUser.id) {
          this.loadUserAccounts(this.currentUser.id, true);
        }
      });
    },
    onDeleteAdvertiser(advertiser) {
      const userAdvertiser = { advertiserId: advertiser.id, userId: this.selectedUserId };
      usersApi.removeAdvertiser(this.selectedUserId, userAdvertiser).then(() => {
        this.getUserAccountAdvertiser(this.selectedUserId);
        if (this.selectedUserId === this.currentUser.id) {
          this.loadUserAccounts(this.currentUser.id, true);
        }
      });
    },
    addAdvertiser() {
      this.addAdvertiserModal = true;
    },
    hasAdvertisers(advertisers) {
      return !isBlank(advertisers);
    },
    onUserSelect(user) {
      this.showMsg = null;
      this.selectedUserId = user;
    },
    async onCloseUserModal(userId) {
      this.showNewOrEditUserModal = false;

      if (!userId) {
        return;
      }
      this.showLoader = true;
      await this.loadAllUsers(true);

      this.selectedUserId = userId;
      this.getUserAccountAdvertiser(userId);
      this.showLoader = false;
    },
    onCloseModal() {
      this.addAccountModal = false;
      this.getUserAccountAdvertiser(this.selectedUserId);
      if (this.selectedUserId === this.currentUser.id) {
        this.loadUserAccounts(this.currentUser.id, true);
      }
    },
    onCloseAdvertiserModal() {
      this.addAdvertiserModal = false;
      this.getUserAccountAdvertiser(this.selectedUserId);
      if (this.selectedUserId === this.currentUser.id) {
        this.loadUserAccounts(this.currentUser.id, true);
      }
    },
    getUserAccountAdvertiser(userId) {
      if (this.userRole !== 'INTERNAL_ADMIN' && userId) {
        // this.showLoader = true;
        this.externalUser = false;
        accountApi
          .userAccount(userId)
          .then((res) => {
            if (res) {
              const sortedList = commonHelper.caseInsensitiveSort([].concat(res), 'name');
              this.userAccounts = sortedList;
            }
            // this.showLoader = false;
          })
          .catch((e) => {
            // this.showLoader = false;
            console.error('Failed to userAccount', e);
          });
        if (
          this.userRole === 'EXTERNAL_SUB_ADMIN' ||
          this.userRole === 'EXTERNAL_USER' ||
          this.userRole === 'EXTERNAL_ADMIN'
        ) {
          this.externalUser = true;
          accountApi
            .userManagedAccount(userId)
            .then((res) => {
              if (res) {
                const sortedList = commonHelper.caseInsensitiveSort([].concat(res), 'name');
                this.userManagedAccounts = sortedList;
              }
              // this.showLoader = false;
            })
            .catch((e) => {
              // this.showLoader = false;
              console.error('Failed to userAccount', e);
            });
        }
      }
    },
    sortedAdvertisers(selectedAccount) {
      return commonHelper.caseInsensitiveSort([].concat(selectedAccount.advertisers), 'name');
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal-mask {
  .modal-container {
    background-color: #212429;
    min-height: 130px;
  }
  .modal-wrapper {
    margin: 0 auto;
  }
}
.users-grid {
  display: flex;
  .user-select {
    flex-grow: 1;
  }
  .user-action {
    display: flex;
    align-items: center;
    .box {
      margin: 0 4px;
    }
  }
}
.flex {
  display: flex;
}
.accounts-container {
  padding-top: 0px !important;
}
.light-theme {
  .detail-table {
    tr {
      border-bottom: 0px !important;
    }
  }
}
.detail-table {
  width: 95%;
  margin: 0px 8px;
}
.top-li {
  .button-box {
    top: 0;
  }
  a {
    svg {
      font-size: 19px;
      color: #ddd;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
.account-advertiser-list {
  max-height: 400px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.accounts-grid {
  display: contents;
}
.px-2 {
  padding: 0px 8px;
}
.sub-li-item {
  display: flex;
}
.sub-li-item.li-last {
  line-height: 45px;
}
.text-white {
  color: white;
  opacity: 0.75;
}
.detail-table {
  tr {
    &:hover {
      .sub-li {
        span {
          text-overflow: initial;
          // white-space: break-spaces;
        }
      }
    }
  }
}
.sub-li {
  padding: 10px 10px 10px 0;
  margin-top: 15px;
  font-size: 14px;
  //vertical-align: baseline;
  vertical-align: top;
  max-width: 200px;
  line-height: 20px;
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    &.intip4 {
      overflow: visible;
    }
  }
  // height: 30px;
  .button-box {
    top: -2px;
  }
  a {
    font-size: 14px;
    svg {
      font-size: 14px;
      color: #cad1d6;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
a {
  svg {
    color: #fff;
    &:hover {
      color: var(--adminprimarycolor) !important;
    }
  }
}
.com-title {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 20px;
  margin-left: 12px !important;
  margin-right: 30px !important;
  padding: 10px 10px 10px 0;
  span {
    text-transform: capitalize;
  }
}
.actions-btn {
  margin-bottom: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.sm {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  border-top: 1px solid var(--adminprimarycolor);
  padding: 10px 0 20px 0;
  width: 100%;
  text-align: left;
  color: #fff;
}
.list-box {
  position: relative;
  z-index: 2;
  color: #fff;
  background-color: #212429;
  border-radius: 0;
  padding: 30px 20px;
  width: 100%;
  min-width: 900px;
  max-width: 900px;
  display: block;
  margin: 0 auto;
  ul {
    margin-bottom: 10px;
    ul {
      border: 0;
      margin: 10px 0 0 0;
    }
    li {
      font-size: 12px;
      line-height: 0.75rem;
      color: rgba(130, 130, 140, 0.8);
      padding-bottom: 5px;
      padding-top: 5px;
      margin-bottom: 0;
      position: relative;

      li {
        border-bottom: 1px solid rgba(170, 170, 170, 0.1);
        padding: 22px 15px 15px 10px;
        list-style: none;
        list-style-position: inside;
        font-size: 13px;
        &::first-child {
          margin-top: 20px;
          right: 20px;
        }
        a {
          color: #848890;
        }
        // .box {
        //   svg {
        //     color: #848890;
        //     &:hover {
        //       color: var(--adminprimarycolor) !important;
        //     }
        //   }
        // }

        .button-box {
          top: 10px;
          //line-height: 34px;
          right: 2px;
          .box {
            svg {
              color: #848890;
              &:hover {
                color: var(--adminprimarycolor) !important;
              }
            }
          }
        }
      }
      a {
        color: #cad1d6;
        display: inline;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.button-box {
  top: 10px;
  line-height: 25px;
  right: 2px;
  .box {
    svg {
      color: #848890;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--adminprimarycolor);
  border-radius: 3px;
  appearance: none;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 19px;
  line-height: 20px;
  color: var(--adminovertext);
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
.list-box ul li.sub-li {
  margin-left: 12px !important;
  margin-right: 30px !important;
}

::v-deep .form-input-wrap {
  min-height: 40px;
  text-transform: capitalize;
  cursor: pointer;
  background-color: #383a44;
  border: 1px solid rgba(170, 170, 170, 0.1);
  border-radius: 4px;
  color: #cad1d6;
  font-size: 14px;
}
::v-deep .form-input .form-input-field.v-select .vs__search {
  padding: 4px 6px;
  color: #cad1d6;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

::v-deep
  .form-input
  .form-input-field.v-select
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  color: #cad1d6;
  font-size: 14px;
}
::v-deep .form-input-wrap .form-click .action-icons svg,
::v-deep .form-input-wrap .form-click .selected-text {
  font-size: 14px;
}
::v-deep
  .form-input
  .form-input-field.v-select.vs--open
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  transform: rotate(-180deg);
  font-size: 14px;
}
::v-deep svg {
  font-size: 14px;
}
.sub-li {
  margin-left: 12px !important;
  margin-right: 30px !important;
  line-height: 25px;
}
::v-deep .intip4 {
  position: relative;
  top: 0;
  svg {
    color: #fff !important;
    opacity: 0.75;
    &:hover {
      color: var(--adminprimarycolor) !important;
    }
  }
}
::v-deep .intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  width: 100px;
  padding: 10px 12px;
  margin-left: -50px;
  font-size: 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #fdea85;
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
}
::v-deep .confirm-modal-users .modal-wrapper-box {
  padding-bottom: 0 !important;
  display: flex !important;
  align-items: center !important;
}
::v-deep .confirm-modal-users .selfServBox .modal-container {
  min-height: 30px !important;
  /* min-width: 300px !important; */
  max-width: unset !important;
  min-width: unset !important;
  width: 100% !important;
  color: #a1a5af !important;
}

::v-deep .confirm-modal-users .modal-mask .selfServBox .modal-container .modal-footer {
  background-color: unset !important;
  text-align: center !important;
}
::v-deep .confirm-modal-users .modal-mask .selfServBox .modal-container .container-text {
  text-align: center;
}
::v-deep .confirm-modal-users .modal-mask .selfServBox {
  overflow-x: hidden !important;
}
::v-deep .form-input .form-input-field.v-select .vs__selected-options {
  padding: 0 6px !important;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected {
  padding: 0 6px !important;
  margin: 0 4px !important;
  text-transform: none;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #323239 !important;
}
@media screen {
  * {
    box-sizing: border-box;
  }
  .intip4 {
    position: relative;
    top: 0;
  }
  .intip4[data-tool-tip]::after {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 99;
    display: block;
    min-width: 140px;
    padding: 10px 12px;
    margin-left: -50px;
    font-size: 12px;
    line-height: 1.2em;
    text-align: center;
    pointer-events: none;
    content: attr(data-tool-tip);
    border-radius: 3px;
    transition: ease 0.3s;
    transform: scale(0);
    transform-origin: bottom center;
  }
  .intip4[data-tool-tip]::after {
    width: auto;
    margin-left: -70px;
    font-size: 12px;
    font-weight: 400;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
  }
  .intip4[data-tool-tip]:hover::after {
    bottom: 25px;
    transform: scale(1);
  }
}
p {
  border-bottom: none !important;
}

.msg {
  padding: 5px;
  font-size: 20px;
  color: #69eb0d;
  letter-spacing: 0.9px;
}
</style>
