<template>
  <div class="d-board">
    <b-flip-header
      :show-logo="false"
      :show-top-navigation="false"
      :show-title="true"
      title="Account Settings"
    />
    <Admin />
  </div>
</template>

<script>
import BFlipHeader from '~/components/elements/b-flip-header.vue';
import Admin from './Admin.vue';

export default {
  name: 'AdminSettings',
  components: { Admin, BFlipHeader },
};
</script>

<style lang="scss" scoped></style>
