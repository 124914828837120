<template>
  <b-modal
    :header="true"
    :header-text="customHeading"
    :width="width"
    :is-self-serve-modal="true"
    @close="onClickCancel"
  >
    <div slot="modal-body" class="modal-wrapper-box">
      <div class="modal-container">
        <div class="container-text">{{ type }}</div>
      </div>
    </div>
    <div slot="modal-footer" class="action-ftr">
      <div class="actions-btn">
        <k-button v-if="isRemove" :size="3" label="REMOVE" class="mr-20" @click="onClickRemove" />
        <k-button v-if="isCancel" :size="3" label="CLOSE" class="mr-20" @click="onClickCancel" />
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'FlipConfirmationModal',
  components: {
    BModal: () => import(/* webpackChunkName: "b-modal" */ '~/components/elements/b-modal.vue'),
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: () => '700px',
    },
    customHeading: {
      type: String,
      required: false,
      default: () => '',
    },
    isCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClickCancel(ev) {
      this.$emit('cancel', ev);
    },
    onClickRemove(ev) {
      this.$emit('remove', ev);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .btn-outline {
  border: none !important;
}
::v-deep .btn-outline .k-btn.btn-size-3 {
  background-color: transparent;
  border: 1px solid #d6d6d6;
  color: #555 !important;
  span {
    color: #555 !important;
    &:hover {
      color: #fff !important;
    }
  }
}
::v-deep .k-btn.btn-size-3 span.label {
  font-size: 12px !important;
  font-family: 'Manrope';
}
.mr-20 {
  margin-right: 20px;
}
::v-deep .modal-wrapper.selfServBox {
  width: 700px;
}
::v-deep .modal-wrapper.selfServBox .modal-wrapper-box {
  padding-bottom: 100px;
}
::v-deep .selfServBox .modal-container .modal-footer {
  background-color: #eef3f6 !important;
  height: 80px !important;
  text-align: left !important;
  padding: 20px 28px !important;
  border-radius: 0 0 7px 7px !important;
}
::v-deep .cdp-top.selfServeContainer {
  height: 68px !important;
  padding: 20px 28px !important;
  border-bottom: 1px solid #e9eaeb !important;
}
</style>
