<template>
  <b-modal
    :header="true"
    :header-text="'Send Invite'"
    :width="width"
    :is-self-serve-modal="true"
    @close="onClickCancel"
  >
    <div slot="modal-body" class="modal-wrapper-box">
      <div class="modal-container">
        <div class="container-text">Are you sure you want to send invite to {{ email }}?</div>
      </div>
    </div>
    <div slot="modal-footer" class="action-ftr">
      <div class="actions-btn">
        <k-button :size="3" label="SEND" class="mr-20" @click="onClickSend" />
        <k-button :size="3" label="CLOSE" class="btn-outline" @click="onClickCancel" />
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'UserConfirmModal',
  components: {
    BModal: () => import(/* webpackChunkName: "b-modal" */ '~/components/elements/b-modal.vue'),
  },
  props: {
    email: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: () => '700px',
    },
  },
  methods: {
    onClickCancel(ev) {
      this.$emit('cancel', ev);
    },
    onClickSend(ev) {
      this.$emit('send', ev);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .btn-outline {
  border: none !important;
}
::v-deep .btn-outline .k-btn.btn-size-3 {
  background-color: transparent;
  border: 1px solid #d6d6d6;
  color: #555 !important;
  span {
    color: #555 !important;
    &:hover {
      color: #fff !important;
    }
  }
}
::v-deep .k-btn.btn-size-3 span.label {
  font-size: 12px !important;
  font-family: 'Manrope';
}
.mr-20 {
  margin-right: 20px;
}
</style>
