<template>
  <div class="d-board">
    <div class="container accounts-container">
      <div class="mid-area list-box">
        <ul>
          <li v-if="!showLoader" class="users-grid">
            <div class="user-select">
              <k-select
                :options="sortedAccounts"
                label-key="name"
                :multiple="false"
                :value="selectedAccountName"
                :searchable="true"
                label="Please select account"
                @input="onAccountSelect"
              />
            </div>
            <div class="user-action">
              <div v-if="hasPermission(E_ACCOUNT, PRIVILEGE_TO_CREATE)" class="box">
                <span class="intip4" data-tool-tip="Add Account">
                  <font-awesome-icon :icon="['far', 'plus']" @click="displayNewAccountModal" />
                </span>
              </div>
            </div>
          </li>
          <li v-if="selectedUserAccount && !showLoader" class="sub-li">
            <a>{{ selectedUserAccount.name }}</a>
            <div class="button-box">
              <div class="box">
                <span class="intip4" data-tool-tip="Edit Account">
                  <font-awesome-icon
                    v-if="hasPermission(E_ACCOUNT, PRIVILEGE_TO_CREATE) && hasPermissionToAddEdit()"
                    :icon="['fas', 'pencil']"
                    @click="displayEditAccountModal(selectedUserAccount.id)"
                  />
                </span>
              </div>
              <div class="box">
                <span class="intip4" data-tool-tip="Add Advertiser">
                  <font-awesome-icon
                    v-if="hasPermission(E_ADVERTISER, PRIVILEGE_TO_CREATE)"
                    :icon="['far', 'plus']"
                    @click="displayNewAdvertiserModal(selectedUserAccount)"
                  />
                </span>
              </div>
              <div class="box">
                <span class="intip4" data-tool-tip="Show Account Users">
                  <font-awesome-icon
                    :icon="['far', 'user']"
                    @click="displayAccountUsersModal(selectedUserAccount.id)"
                  />
                </span>
              </div>
              <div class="box">
                <span class="intip4" data-tool-tip="Add Dashboard URL">
                  <font-awesome-icon
                    v-if="isAdmin"
                    :icon="['far', 'link']"
                    @click="displayAddDashboardUrl(selectedUserAccount)"
                  />
                </span>
              </div>
            </div>
          </li>
          <li v-if="showLoader">
            <b-loading-spinner class="txt-center" />
          </li>
          <li v-if="selectedUserAccount" class="sub-li">
            <ul class="user-section">
              <li>
                <div class="user-hd-blk">
                  <div class="user-hd-col-lg">Advertiser Name</div>
                  <div class="user-hd-col-lg">Xandr ID</div>
                </div>
              </li>
              <li v-for="(uaAdvertiser, uai) in sortedAdvertisers(selectedUserAccount)" :key="uai">
                <div class="user-blk">
                  <div class="user-col-lg">
                    <a>{{ uaAdvertiser.name }}</a>
                  </div>
                  <div class="user-col-lg">{{ uaAdvertiser.xandrAdvertiserId }}</div>
                  <div class="user-col-lg">
                    <div class="button-box">
                      <div class="box">
                        <span class="intip4" data-tool-tip="Edit Advertiser">
                          <font-awesome-icon
                            v-if="
                              hasPermission(E_ADVERTISER, PRIVILEGE_TO_CREATE) &&
                                hasPermissionToAddEdit()
                            "
                            :icon="['fas', 'pencil']"
                            @click="
                              displayEditAdvertiserModal(selectedUserAccount.id, uaAdvertiser.id)
                            "
                          />
                        </span>
                      </div>
                      <div class="box">
                        <span class="intip4" data-tool-tip="View Pixels">
                          <font-awesome-icon
                            :icon="['fa', 'code']"
                            @click="
                              displayAdvertiserPixelsModal(selectedUserAccount.id, uaAdvertiser)
                            "
                          />
                        </span>
                      </div>
                      <div class="box">
                        <span class="intip4" data-tool-tip="Show Advertiser Users">
                          <font-awesome-icon
                            :icon="['far', 'user']"
                            @click="
                              displayAdvertiserUsersModal(selectedUserAccount.id, uaAdvertiser)
                            "
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <br /><br />
      <add-edit-advertiser
        v-if="showNewOrEditAdvertiserModal"
        :initial-advertiser="advertiserInView"
        :account-ref="userAccountInView"
        @close="handleAdvertiserUpdates"
      />

      <br /><br />
      <b-modal v-if="showEditAccountModal" @close="showEditAccountModal = false">
        <div slot="modal-body" :class="['row', 'center-xs', 'modal-wrapper-box']">
          <div class="modal-container">
            <add-account :account-id="editAccountId" @close="handleAccountUpdates" />
          </div>
        </div>
      </b-modal>
      <br /><br />

      <b-modal
        v-if="showAccountWizardModal"
        @close="handleAccountWizardUpdates({ id: selectedAccountId })"
      >
        <div slot="modal-body" :class="['row', 'center-xs', 'modal-wrapper-box']">
          <div class="modal-container">
            <add-account-wizard :account-ref="editAccount" @close="handleAccountWizardUpdates" />
          </div>
        </div>
      </b-modal>
      <br /><br />

      <b-modal v-if="showAccessToAccountModal" @close="showAccessToAccountModal = false">
        <div slot="modal-body" class="modal-wrapper-box">
          <div class="modal-container">
            <div class="modal-header">
              <span class="com-title">{{ userAccountInView.name }}</span>
            </div>
            <div v-if="msg" class="msg">{{ msg }}</div>
            <div class="modal-body">
              <div><p class="sm">Select user to assign to this account</p></div>
              <div class="table-wrap">
                <table class="tbl tbl-export">
                  <tbody>
                    <tr v-if="responseMessage">
                      <td>{{ responseMessage }}</td>
                    </tr>
                    <tr class="no-bg no-bg-no-pg mg-tp-20">
                      <td colspan="2" align="right">
                        <div class="form-group">
                          <k-select
                            :options="sortedAccountUsers"
                            label-key="displayUserName"
                            :multiple="false"
                            :value="selectedUserId"
                            :searchable="true"
                            label="Please select user"
                            @input="onUserSelect"
                          />
                        </div>
                      </td>
                      <td align="right">
                        <div class="actions-btn">
                          <k-button
                            :size="3"
                            label="APPLY"
                            class="wide-btn"
                            @click="assignUserToAccount()"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr class="no-bg no-bg-no-pg">
                      <td colspan="2" align="right">
                        &nbsp;
                      </td>
                      <td align="right">
                        <div v-if="hasPermission(E_USER, PRIVILEGE_TO_CREATE)" class="actions-btn">
                          <k-button :size="3" label="CREATE NEW USER" @click="addUser('account')" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div><p class="sm2">Users who have access to the Account</p></div>
              <div class="form-group">
                <input v-model="searchText" type="text" aria-describedby="emailHelp" />
              </div>
              <div>
                <k-checkbox
                  :checked="showInternalAdminsInList"
                  label="Show Digital Remedy Users?"
                  @change="setShowInternalAdminsInList"
                  @selected="setShowInternalAdminsInList"
                />
              </div>
              <div class="table-wrap">
                <div class="table-scroll">
                  <table
                    v-if="filteredAccountUserList.length && !loadingAccountUserList"
                    class="tbl tbl-export"
                  >
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Organization</th>
                        <th>Role</th>
                        <th>Invite Accepted</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(apl, apli) in filteredAccountUserList"
                        :key="apli"
                        class="sub-row account-table-row"
                      >
                        <td>{{ apl.displayUserName }}</td>
                        <td>{{ apl.organizationName }}</td>
                        <td>{{ apl.role ? apl.role.name : '' }}</td>
                        <td>{{ apl.inviteAccepted ? '✓' : '𐄂' }}</td>
                        <td>
                          <span class="intip4" data-tool-tip="Remove User">
                            <font-awesome-icon
                              v-if="isShowDeleteBtn(apl)"
                              :icon="['fas', 'trash']"
                              @click="removeUserFromAccount(apl.id)"
                            />
                          </span>
                          <span
                            v-if="!apl.inviteAccepted"
                            class="intip4"
                            data-tool-tip="Re-Invite User"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'envelope']"
                              @click="reInviteUser(apl)"
                            />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    v-else-if="!filteredAccountUserList.length && !loadingAccountUserList"
                    class="txt-center"
                  >
                    No data is available to show
                  </div>
                  <b-loading-spinner v-else-if="loadingAccountUserList" class="txt-center" />
                </div>
                <div class="form-group" style="text-align: right; float:right; display: contents;">
                  <div class="actions-btn close-btn">
                    <k-button :size="3" label="CLOSE" @click="showAccessToAccountModal = false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <br /><br />

      <b-modal v-if="showAccessToAdvertiserModal" @close="showAccessToAdvertiserModal = false">
        <div slot="modal-body" class="modal-wrapper-box">
          <div class="modal-container">
            <div class="modal-header">
              <span class="com-title">{{ advertiserInView.name }}</span>
            </div>
            <div v-if="msg" class="msg">{{ msg }}</div>
            <div class="modal-body">
              <div><p class="sm">Select user to assign to this Advertiser</p></div>
              <div class="table-wrap">
                <table class="tbl tbl-export">
                  <tbody>
                    <tr v-if="responseMessage">
                      <td>{{ responseMessage }}</td>
                    </tr>
                    <tr class="no-bg no-bg-no-pg mg-tp-20">
                      <td colspan="2" align="right">
                        <div class="form-group">
                          <k-select
                            :options="sortedAdvertiserUsers"
                            label-key="displayUserName"
                            :multiple="false"
                            :value="selectedUserId"
                            :searchable="true"
                            label="Please select user"
                            @input="onUserSelect"
                          />
                        </div>
                      </td>
                      <td align="right">
                        <div class="actions-btn">
                          <k-button
                            :size="3"
                            label="APPLY"
                            class="wide-btn"
                            @click="assignUserToAdvertiser()"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr class="no-bg no-bg-no-pg">
                      <td colspan="2" align="right">
                        &nbsp;
                      </td>
                      <td align="right">
                        <div v-if="hasPermission(E_USER, PRIVILEGE_TO_CREATE)" class="actions-btn">
                          <k-button
                            :size="3"
                            label="CREATE NEW USER"
                            @click="addUser('advertiser')"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div><p class="sm2">Users who have access to the Advertiser</p></div>
              <div class="form-group">
                <input v-model="searchText" type="text" aria-describedby="emailHelp" />
              </div>
              <div class="table-wrap">
                <div class="table-scroll">
                  <table
                    v-if="filteredAdvertiserUserList.length && !loadingAdvertiserUserList"
                    class="tbl tbl-export"
                  >
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Organization</th>
                        <th>Role</th>
                        <th>Invite Accepted</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(apl, apli) in filteredAdvertiserUserList"
                        :key="apli"
                        class="sub-row account-table-row"
                      >
                        <td class="account-table-td">{{ apl.displayUserName }}</td>
                        <td class="account-table-td">{{ apl.organizationName }}</td>
                        <td class="account-table-td">{{ apl.role ? apl.role.name : '' }}</td>
                        <td class="account-table-td">{{ apl.inviteAccepted ? '✓' : '𐄂' }}</td>
                        <td class="account-table-td">
                          <span
                            v-if="!apl.inviteAccepted"
                            class="intip4"
                            data-tool-tip="Re-Invite User"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'envelope']"
                              @click="reInviteUser(apl)"
                            />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    v-else-if="!filteredAdvertiserUserList.length && !loadingAdvertiserUserList"
                    class="txt-align-center"
                  >
                    No data is available to show
                  </div>
                  <b-loading-spinner v-else-if="loadingAdvertiserUserList" class="txt-center" />
                </div>
                <div class="form-group" style="text-align: right; float:right; display: contents;">
                  <div class="actions-btn close-btn">
                    <k-button
                      :size="3"
                      label="CLOSE"
                      @click="showAccessToAdvertiserModal = false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <br /><br />

      <b-modal
        v-if="showListOfPixelsModal"
        class="show-pixel-screen"
        @close="showListOfPixelsModal = false"
      >
        <div slot="modal-body" class="modal-wrapper-box">
          <div class="modal-container">
            <div class="modal-header">
              <span class="com-title">{{ advertiserNameInView }}</span>
            </div>
            <div><p class="sm">List of Pixels</p></div>
            <div class="modal-body">
              <div class="table-wrap">
                <div>
                  <table
                    v-if="advertiserPixelList.length && !loadingAdvertiserPixelList"
                    class="tbl tbl-export"
                  >
                    <thead>
                      <tr>
                        <th style="width: 220px">Pixel ID</th>
                        <th style="width: 140px">Pixel Name</th>
                        <th style="width: 100px">Conversion Type</th>
                        <th style="width: 50px">Active</th>
                        <th style="width: 125px">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(apl, apli) in advertiserPixelList"
                        :key="apli"
                        class="sub-row account-table-row"
                      >
                        <td class="account-table-td" style="width: 220px">{{ apl.flip_id }}</td>
                        <td class="account-table-td" style="width: 140px">{{ apl.name }}</td>
                        <td class="account-table-td" style="width: 100px">
                          {{ apl.conversion_type }}
                        </td>
                        <td class="account-table-td" style="width: 50px">
                          {{ apl.active ? '✓' : '𐄂' }}
                        </td>

                        <td class="account-table-td" style="width: 125px" align="center">
                          <span
                            v-if="hasPermissionToAddEdit()"
                            class="intip4 mg-20"
                            data-tool-tip="Edit Pixel"
                            @click="
                              flipPixel = apl;
                              showNewOrEditPixelModal = true;
                            "
                          >
                            <font-awesome-icon
                              :icon="['fas', 'pencil']"
                              @click="
                                flipPixel = apl;
                                showNewOrEditPixelModal = true;
                              "
                            />
                          </span>

                          <span
                            class="intip4"
                            data-tool-tip="View Pixel Fires"
                            @click="
                              flipPixel = apl;
                              showViewPixelModal = true;
                            "
                          >
                            <font-awesome-icon
                              :icon="['fas', 'eye']"
                              @click="
                                flipPixel = apl;
                                showViewPixelModal = true;
                              "
                            />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    v-else-if="!advertiserPixelList.length && !loadingAdvertiserPixelList"
                    class="txt-align-center"
                  >
                    {{ emptyPixelListMessage() }}
                  </div>
                  <b-loading-spinner v-else-if="loadingAdvertiserPixelList" class="txt-center" />
                  <div class="actions-btn">
                    <k-button
                      v-if="showCreatePixelButton() && hasPermissionToAddEdit()"
                      :size="3"
                      label="CREATE NEW PIXEL"
                      @click="
                        showListOfPixelsModal = false;
                        initNewPixel();
                        showNewOrEditPixelModal = true;
                      "
                    />
                  </div>
                  <div class="actions-btn">
                    <k-button :size="3" label="CLOSE" @click="showListOfPixelsModal = false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <br /><br />

      <b-modal
        v-if="showNewOrganizationModal"
        @close="
          loadAllOrganizations();
          showNewOrganizationModal = false;
        "
      >
        <div slot="modal-body" class="modal-wrapper-box">
          <div class="modal-container">
            <add-organization @close="showNewOrganizationModal = false" />
          </div>
        </div>
      </b-modal>
      <br /><br />
      <add-dashboard-URL
        v-if="showDashboardModal"
        :account-obj="selectedUserAccount"
        :show-modal="showDashboardModal"
        @onClickDashboardModalClose="onClickDashboardModalClose"
      />

      <add-user
        v-if="showNewUserModal"
        :account-ref="userAccountInView"
        :advertiser-ref="advertiserInView"
        :add-user-for="addUserFor"
        @close="handleUserUpdates"
      />

      <br /><br />

      <add-edit-pixel
        v-if="showNewOrEditPixelModal"
        :initial-pixel="flipPixel"
        :advertiser-in-view="advertiserInView"
        @close="
          showNewOrEditPixelModal = false;
          loadFlipPixels();
          showListOfPixelsModal = true;
        "
      />

      <view-pixel
        v-if="showViewPixelModal"
        :initial-pixel="flipPixel"
        :advertiser-in-view="advertiserInView"
        @close="
          showViewPixelModal = false;
          loadFlipPixels();
          showListOfPixelsModal = true;
        "
      />
      <div class="confirm-modal-accounts">
        <ConfirmModal
          v-if="showConfirmBoxModal"
          width="400px"
          :type="confirmationObj.type"
          @cancel="showConfirmBoxModal = false"
          @remove="onClickConfirmSubmit"
        />
        <ConfirmModalReInvite
          v-if="showConfirmBoxModalRI"
          width="495px"
          :email="confirmationReInviteObj.email"
          @cancel="showConfirmBoxModalRI = false"
          @send="onClickConfirmReInviteSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import commonHelper, { isBlank } from 'adready-api/helpers/common';
import { User } from 'adready-api/model/user';
import KSelect from 'adready-vue/components/elements/k-select.vue';
import { PRIVILEGE_TO_CREATE, E_ACCOUNT, E_ADVERTISER, E_USER } from 'adready-api/auth-constant';
import usersApi from 'adready-api/api/user';
import flipPixelApi from '~/api/flipPixel';
import forklift from '~/components/mixins/forklift-mixin';
import userApi from '~/api/user';
import { buildQueryString } from '~/helpers/global/url-helpers';
import authzMixin from '~/components/mixins/authz-mixin';
import ConfirmModal from '~/pages/ConfirmModal.vue';
import ConfirmModalReInvite from '~/pages/ConfirmModalReInvite.vue';
import AddDashboardURL from '~/components/admin/AddDashboardURL.vue';

function newFlipPixel() {
  return {
    name: '',
    active: true,
    apn_name: '',
    apn_adv_id: '',
    conversion_type: 'Universal',
    piggyback_pixels: [],
  };
}
export default {
  components: {
    BModal: () => import(/* webpackChunkName: "b-modal" */ '~/components/elements/b-modal.vue'),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    AddEditAdvertiser: () =>
      import(
        /*  webpackChunkName: "add-edit-advertiser"
         */ '~/components/admin/add-edit-advertiser.vue'
      ),
    AddEditPixel: () =>
      import(/* webpackChunkName: "add-edit-pixel" */ '~/components/admin/add-edit-pixel.vue'),
    ViewPixel: () =>
      import(/* webpackChunkName: "view-pixel" */ '~/components/admin/view-pixel.vue'),
    AddOrganization: () =>
      import(/* webpackChunkName: "add-organization" */ '~/components/admin/add-organization.vue'),
    AddAccount: () =>
      import(/* webpackChunkName: "add-account" */ '~/components/admin/add-account.vue'),
    AddAccountWizard: () =>
      import(
        /* webpackChunkName: "add-account-wizard" */ '~/components/admin/add-account-wizard.vue'
      ),
    AddUser: () => import(/* webpackChunkName: "add-user" */ '~/components/admin/add-user.vue'),
    KSelect,
    ConfirmModal,
    ConfirmModalReInvite,
    AddDashboardURL,
  },
  mixins: [forklift, authzMixin],
  data() {
    return {
      selectedUserId: null,
      selectedAccountId: null,
      selectedAccountParentId: null,
      showNewOrEditAccountModal: false,
      showEditAccountModal: false,
      showAccountWizardModal: false,
      showNewOrganizationModal: false,
      showAccessToAccountModal: false,
      showAccessToAdvertiserModal: false,
      showDashboardModal: false,
      showNewUserModal: false,
      showNewOrEditPixelModal: false,
      showViewPixelModal: false,
      showListOfPixelsModal: false,
      showNewOrEditAdvertiserModal: false,
      loadingAdvertiserPixelList: true,
      advertiserPixelList: [],
      loadingAccountUserList: true,
      loadingAdvertiserUserList: true,
      showInternalAdminsInList: false,
      accountUserList: [],
      advertiserUserList: [],
      advertiserIDInView: null,
      advertiserNameInView: null,
      userAccountIDInView: null,
      flipPixel: newFlipPixel(),
      editAccountId: null,
      editAccount: null,
      user: new User(),
      responseMessage: null,
      PRIVILEGE_TO_CREATE,
      E_ACCOUNT,
      E_ADVERTISER,
      E_USER,
      showConfirmBoxModal: false,
      showConfirmBoxModalRI: false,
      confirmationObj: null,
      addUserFor: '',
      searchText: '',
      confirmationReInviteObj: null,
      showMsg: null,
      showLoader: false,
    };
  },

  computed: {
    allUsers: get('common/allUsers'),
    currentUser: get('common/currentUser'),
    userAccounts: get('common/userAccounts'),
    advertiser: get('common/advertiser'),
    organizations: get('common/allOrganizations'),
    allAccounts: get('common/accounts'),
    allUsersForCurrentUser: get('common/allUsersForCurrentUser'),

    /**
     * for Internal Admin return all the accounts other wise return user's accounts.
     */
    allUserAccounts() {
      if (this.isInternalAdmin) {
        return this.allAccounts;
      }
      return this.userAccounts;
    },

    allowedRolesForAdvertiserUser() {
      if (this.isInternalAdmin) {
        return ['INTERNAL_USER', 'EXTERNAL_USER'];
      }
      return ['EXTERNAL_USER'];
    },

    msg() {
      if (!isBlank(this.showMsg)) {
        return this.showMsg;
      }
      return null;
    },
    selectedAccountName() {
      return this.getAccountName(this.selectedAccountId);
    },
    allUsersForLoggedInUser() {
      if (this.isInternalAdmin) {
        return this.allUsers;
      }
      return this.allUsersForCurrentUser;
    },

    sortedAccounts() {
      const accounts = this.allUserAccounts;
      if (isBlank(accounts)) {
        return [];
      }

      const sortedAccounts = commonHelper.caseInsensitiveSort([].concat(accounts), 'name');
      return sortedAccounts.filter((u) => !isBlank(u.name));
    },
    selectedUserAccount() {
      if (isBlank(this.selectedAccountId)) {
        return undefined;
      }
      return this.allUserAccounts.find((item) => item.id === this.selectedAccountId);
    },
    sortedAccountUsers() {
      if (isBlank(this.allUsersForLoggedInUser)) {
        return [];
      }
      const users = JSON.parse(JSON.stringify(this.allUsersForLoggedInUser));
      users.forEach((u) => {
        u.displayUserName = `${u.userName || ''} (${u.email})`;
      });
      const allowedRoles = ['EXTERNAL_SUB_ADMIN', 'EXTERNAL_ADMIN'];
      // const allowedOrgs = [1, this.userAccountInView.organizationId];
      const assignedUsers = isBlank(this.accountUserList)
        ? []
        : this.accountUserList.map((u) => u.id);
      const filteredUsers = users.filter(
        (u) =>
          u.role &&
          allowedRoles.includes(u.role.name) &&
          // allowedOrgs.includes(u.organizationId) &&
          !assignedUsers.includes(u.id)
      );
      return commonHelper.caseInsensitiveSort([].concat(filteredUsers), 'displayUserName');
    },
    sortedAdvertiserUsers() {
      if (isBlank(this.allUsersForLoggedInUser)) {
        return [];
      }
      const users = JSON.parse(JSON.stringify(this.allUsersForLoggedInUser));
      users.forEach((u) => {
        u.displayUserName = `${u.userName || ''} (${u.email})`;
      });
      const allowedRoles = this.allowedRolesForAdvertiserUser;
      const allowedOrgs = [1, this.advertiserInView.organizationId];
      const ua = this.allUserAccounts.find((u) => u.id === this.advertiserInView.accountId);
      if (ua) {
        allowedOrgs.push(ua.organizationId);
      }
      const assignedUsers = isBlank(this.advertiserUserList)
        ? []
        : this.advertiserUserList.map((u) => u.id);
      const filteredUsers = users.filter(
        (u) =>
          u.role &&
          allowedRoles.includes(u.role.name) &&
          // allowedOrgs.includes(u.organizationId) &&
          !assignedUsers.includes(u.id)
      );
      return commonHelper.caseInsensitiveSort([].concat(filteredUsers), 'displayUserName');
    },
    filteredAccountUserList() {
      const allowedRoles = ['INTERNAL_ADMIN', 'INTERNAL_SUB_ADMIN'];
      let filteredUsers;
      if (this.showInternalAdminsInList) {
        filteredUsers = this.accountUserList;
      } else {
        filteredUsers = this.accountUserList.filter(
          (u) => u.role && !allowedRoles.includes(u.role.name)
        );
      }
      if (!isBlank(this.searchText)) {
        filteredUsers = filteredUsers.filter((u) => {
          const searchTxt = this.searchText.toLowerCase();
          return (
            u.displayUserName.toLowerCase().includes(searchTxt) ||
            (u.organizationName && u.organizationName.toLowerCase().includes(searchTxt)) ||
            (u.role && u.role.name.toLowerCase().includes(searchTxt))
          );
        });
      }
      return filteredUsers;
    },
    filteredAdvertiserUserList() {
      if (isBlank(this.searchText)) {
        return this.advertiserUserList;
      }
      return this.advertiserUserList.filter((u) => {
        const searchTxt = this.searchText.toLowerCase();
        return (
          u.displayUserName.toLowerCase().includes(searchTxt) ||
          (u.organizationName && u.organizationName.toLowerCase().includes(searchTxt)) ||
          (u.role && u.role.name.toLowerCase().includes(searchTxt))
        );
      });
    },
    userAccountInView() {
      const ua = this.allUserAccounts.find((u) => u.id === this.userAccountIDInView);
      if (!ua) {
        return {};
      }
      return ua;
    },
    advertiserInView() {
      if (!this.userAccountInView.advertisers) {
        return {};
      }
      const adv = this.userAccountInView.advertisers.find((a) => a.id === this.advertiserIDInView);
      if (!adv) {
        return {};
      }
      return adv;
    },
    doesUniversalPixelExist() {
      let hasUniversalPixel = false;
      if (this.advertiserPixelList.length) {
        hasUniversalPixel = this.advertiserPixelList.some((p) => p.conversion_type === 'Universal');
      }

      return hasUniversalPixel;
    },
  },
  mounted() {
    this.loadAccounts(true);
    this.loadAllOrganizations();
    this.loadAllUsersForCurrentUser(this.currentUser?.id, true);
  },

  methods: {
    getAccountName(accountId) {
      const foundAccount = this.allUserAccounts.find((account) => account.id === accountId);
      return foundAccount ? foundAccount.name : null;
    },
    getAccountParentId(accountId) {
      const account = this.allUserAccounts.find((acc) => acc.id === accountId);
      return account ? account.parentId : null;
    },
    hasPermissionToAddEdit() {
      if (this.isInternalAdmin) {
        return true;
      }
      if (this.isExternalAdmin && this.selectedAccountParentId !== null) {
        return true;
      }
      return false;
    },
    isShowDeleteBtn(user) {
      return (
        (user.organizationId === 1 && user.role.name === 'INTERNAL_USER') ||
        (user.organizationId !== 1 && user.role.name === 'EXTERNAL_USER')
      );
    },
    reInviteUser(user) {
      this.showMsg = null;
      this.onClickConfirmReInvite({
        email: user.email,
        value: user,
      });
    },
    onClickConfirmReInvite(item) {
      this.confirmationReInviteObj = item;
      this.showConfirmBoxModalRI = true;
    },
    onClickConfirmReInviteSubmit() {
      this.sendInvite(this.confirmationReInviteObj.value);
      this.showConfirmBoxModalRI = false;
    },
    async sendInvite(user) {
      try {
        // uncomment at the time of commit
        await usersApi.sendInvite(user.id);
        this.showMsg = 'User invited successfully.';
      } catch (err) {
        this.showMsg = 'Unable to send an invitation email.';
        console.error(`error sending invite to ${user.email} (id: ${user.id}) user ->`, err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    setShowInternalAdminsInList(val) {
      this.showInternalAdminsInList = val.checked;
    },
    onAccountSelect(accountId) {
      this.selectedAccountId = accountId;
      this.selectedAccountParentId = this.getAccountParentId(accountId);
    },
    onUserSelect(userId) {
      this.responseMessage = null;
      this.selectedUserId = userId;
    },
    displayNewAccountModal() {
      this.editAccount = null;
      this.showAccountWizardModal = true;
    },
    handleAccountWizardUpdates(account) {
      if (account && account.id) {
        this.loadUserAccounts(this.currentUser.id, true).then(() => {
          this.selectedAccountId = account.id;
        });
      }
      this.loadAccounts(true);
      this.editAccount = null;
      this.showAccountWizardModal = false;
    },
    displayEditAccountModal(accountId) {
      this.showEditAccountModal = true;
      this.editAccountId = accountId;
    },
    handleAccountUpdates(account) {
      this.showLoader = true;
      this.showEditAccountModal = false;
      if (!account) {
        this.showLoader = false;
        return;
      }
      this.loadUserAccounts(this.currentUser.id, true)
        .then(() => {
          this.selectedAccountId = account.id;
          this.showNewOrEditAccountModal = false;
          this.showLoader = false;
        })
        .catch((error) => {
          this.showLoader = false;
          console.error('Error loading user accounts:', error);
        });
    },
    displayAccountUsersModal(accountId) {
      this.showMsg = null;
      this.selectedUserId = null;
      this.userAccountIDInView = accountId;
      this.advertiserIDInView = null;
      this.showInternalAdminsInList = false;
      this.showAccessToAccountModal = true;
      this.searchText = '';
      this.loadAccountUsers();
    },
    displayNewAdvertiserModal(account) {
      this.editAccount = account;
      this.showAccountWizardModal = true;
    },
    displayAddDashboardUrl() {
      this.showDashboardModal = true;
    },
    handleAdvertiserUpdates() {
      this.loadAccounts(true);
      this.loadUserAccounts(this.currentUser.id, true);
      this.showNewOrEditAdvertiserModal = false;
    },
    displayEditAdvertiserModal(accountId, advertiserId) {
      this.userAccountIDInView = accountId;
      this.advertiserIDInView = advertiserId;
      this.showNewOrEditAdvertiserModal = true;
    },
    displayAdvertiserPixelsModal(accountId, advertiser) {
      this.userAccountIDInView = accountId;
      this.advertiserIDInView = advertiser.id;
      this.advertiserNameInView = advertiser.name;
      this.showListOfPixelsModal = true;
      this.loadFlipPixels();
    },
    displayAdvertiserUsersModal(accountId, advertiser) {
      this.showMsg = null;
      this.userAccountIDInView = accountId;
      this.advertiserIDInView = advertiser.id;
      this.advertiserNameInView = advertiser.name;
      this.showAccessToAdvertiserModal = true;
      this.searchText = '';
      this.showAccessToAccountModal = false;
      this.selectedUserId = null;
      this.loadAdvertiserUsers();
    },
    assignUserToAccount() {
      if (!this.selectedUserId) {
        return;
      }

      const userAccounts = [{ userId: this.selectedUserId, accountId: this.userAccountIDInView }];
      usersApi.mapAccounts(this.selectedUserId, userAccounts).then(() => {
        this.responseMessage = 'User assigned successfully';
        this.selectedUserId = null;
        this.loadAccountUsers();
      });

      this.selectedUserId = null;
    },
    assignUserToAdvertiser() {
      if (!this.selectedUserId) {
        return;
      }

      const userAdvertiser = [
        {
          userId: this.selectedUserId,
          advertiserId: this.advertiserIDInView,
        },
      ];
      usersApi.mapAdvertisers(this.selectedUserId, userAdvertiser).then(() => {
        this.responseMessage = 'User assigned successfully';
        this.selectedUserId = null;
        this.loadAdvertiserUsers();
      });

      this.showAccessToAccountModal = false;
      this.selectedUserId = null;
    },
    removeUserFromAccount(userIdToRemove) {
      this.onClickConfirm({
        type: 'userAccount',
        value: { accountId: this.userAccountInView.id, userId: userIdToRemove },
      });
    },
    removeUserFromAdvertiser(userIdToRemove) {
      this.onClickConfirm({
        type: 'userAdvertiser',
        value: { advertiserId: this.advertiserIDInView, userId: userIdToRemove },
      });
    },
    onClickConfirm(item) {
      this.confirmationObj = item;
      this.showConfirmBoxModal = true;
    },
    onClickConfirmSubmit() {
      if (this.confirmationObj.type === 'userAccount') {
        this.onDeleteUserAccount(this.confirmationObj.value);
      } else if (this.confirmationObj.type === 'userAdvertiser') {
        this.onDeleteAdvertiser(this.confirmationObj.value);
      }
      this.showConfirmBoxModal = false;
    },
    onDeleteUserAccount(account) {
      usersApi.removeAccount(account.userId, account).then(() => {
        this.loadAccountUsers();
      });
    },
    onDeleteAdvertiser(advertiser) {
      usersApi.removeAdvertiser(advertiser.userId, advertiser).then(() => {
        this.loadAdvertiserUsers(this.currentUser.id, true);
      });
    },
    loadFlipPixels() {
      if (this.isAdvertiserMissingXandrId()) {
        this.advertiserPixelList = [];
        return;
      }
      this.loadingAdvertiserPixelList = true;
      this.advertiserPixelList = [];
      flipPixelApi
        .flipPixels(this.advertiserIDInView, buildQueryString({ showAll: true }))
        .then((pixels) => {
          if (pixels) {
            this.advertiserPixelList = pixels;
          }
        })
        .catch((err) => {
          if (window.$sentry) {
            if (err._reported !== true) {
              window.$sentry.captureException(err);
              err._reported = true;
            }
          }
        })
        .finally(() => {
          this.loadingAdvertiserPixelList = false;
        });
    },
    isAdvertiserMissingXandrId() {
      return (
        isBlank(this.advertiserInView.xandrAdvertiserId) ||
        this.advertiserInView.xandrAdvertiserId === 0
      );
    },
    showCreatePixelButton() {
      return (
        !this.isAdvertiserMissingXandrId() &&
        !this.loadingAdvertiserPixelList &&
        !this.doesUniversalPixelExist
      );
    },
    emptyPixelListMessage() {
      if (this.isAdvertiserMissingXandrId()) {
        return 'Advertiser does not have Xandr Id set';
      }
      return 'No data is available to show';
    },
    initNewPixel() {
      this.flipPixel = newFlipPixel();
      this.flipPixel.apn_adv_id = this.advertiserInView.xandrAdvertiserId;
      this.flipPixel.apn_name = this.advertiserInView.name;
    },
    loadAccountUsers() {
      this.responseMessage = null;
      this.loadingAccountUserList = true;
      this.accountUserList = [];
      userApi
        .listUsers(this.userAccountIDInView)
        .then((users) => {
          if (users) {
            users.forEach((u) => {
              const org = this.organizations.find((o) => o.id === u.organizationId);
              if (org) {
                u.organizationName = org.name;
              }
              u.displayUserName = `${u.userName || ''} (${u.email})`;
            });
            const sortedList = commonHelper.caseInsensitiveSort(
              [].concat(users),
              'displayUserName'
            );
            this.accountUserList = sortedList;
          }
        })
        .catch((err) => {
          if (window.$sentry) {
            if (err._reported !== true) {
              window.$sentry.captureException(err);
              err._reported = true;
            }
          }
        })
        .finally(() => {
          this.loadingAccountUserList = false;
        });
    },
    loadAdvertiserUsers() {
      this.responseMessage = null;
      this.loadingAdvertiserUserList = true;
      this.advertiserUserList = [];
      userApi
        .listAdvertiserUsers(this.advertiserIDInView)
        .then((users) => {
          if (users) {
            users.forEach((u) => {
              const org = this.organizations.find((o) => o.id === u.organizationId);
              if (org) {
                u.organizationName = org.name;
              }
              u.displayUserName = `${u.userName || ''} (${u.email})`;
            });
            const sortedList = commonHelper.caseInsensitiveSort(
              [].concat(users),
              'displayUserName'
            );
            this.advertiserUserList = sortedList;
          }
        })
        .catch((err) => {
          if (window.$sentry) {
            if (err._reported !== true) {
              window.$sentry.captureException(err);
              err._reported = true;
            }
          }
        })
        .finally(() => {
          this.loadingAdvertiserUserList = false;
        });
    },
    sortedAdvertisers(selectedAccount) {
      return commonHelper.caseInsensitiveSort([].concat(selectedAccount.advertisers), 'name');
    },
    addUser(entity) {
      this.addUserFor = entity;
      this.showNewUserModal = true;
    },
    handleUserUpdates() {
      this.showNewUserModal = false;
      this.loadAllUsersForCurrentUser(this.currentUser?.id, true);
      if (this.addUserFor === 'account') {
        this.loadAccountUsers();
        this.showAccessToAccountModal = true;
        this.showAccessToAdvertiserModal = false;
        this.searchText = '';
      } else if (this.addUserFor === 'advertiser') {
        this.loadAdvertiserUsers();
        this.showAccessToAccountModal = false;
        this.showAccessToAdvertiserModal = true;
        this.searchText = '';
      }
      this.addUserFor = '';
    },
    onClickDashboardModalClose() {
      this.showDashboardModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal-mask {
  .modal-container {
    background-color: #212429;
    min-height: 130px;
  }
  .modal-wrapper {
    margin: 0 auto;
    p {
      border-bottom: none;
    }
  }
}
.users-grid {
  display: flex;
  .user-select {
    flex-grow: 1;
  }
  .user-action {
    display: flex;
    align-items: center;
    .box {
      margin: 4px 4px 4px 20px;
    }
  }
}
.accounts-container {
  padding-top: 0px !important;
}
/**custom */
.list-box {
  position: relative;
  z-index: 2;
  color: #fff;
  background-color: #212429;
  border-radius: 0;
  padding: 30px 20px;
  width: 100%;
  display: block;
  min-width: 900px;
  max-width: 900px;
  margin: 0 auto;
  ul {
    margin-bottom: 10px;
    ul {
      border: 0;
      margin: 10px 0 0 0;
    }
    li {
      font-size: 12px;
      line-height: 0.75rem;
      color: rgba(130, 130, 140, 0.8);
      padding-bottom: 5px;
      padding-top: 5px;
      margin-bottom: 0;
      position: relative;

      li {
        border-bottom: 1px solid rgba(170, 170, 170, 0.1);
        padding: 22px 15px 15px 10px;
        list-style: none;
        list-style-position: inside;
        font-size: 13px;
        &::first-child {
          margin-top: 20px;
          right: 20px;
        }
        a {
          color: #848890;
        }
        // .box {
        //   svg {
        //     color: #848890;
        //     &:hover {
        //       color: var(--adminprimarycolor) !important;
        //     }
        //   }
        // }

        .button-box {
          top: 10px;
          line-height: 34px;
          right: 2px;
          .box {
            svg {
              color: #848890;
              &:hover {
                color: var(--adminprimarycolor) !important;
              }
            }
          }
        }
      }
      a {
        color: #cad1d6;
        display: inline;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .button-box {
    position: absolute;
    top: -4px;
    right: 12px;
    line-height: 30px;
    .box {
      display: inline-block;
      margin-left: 10px;
    }
  }
}
.modal-wrapper-box {
  width: 100%;
  min-width: 500px;
  max-width: 650px;
  padding: 15px;
  margin: 0 auto;
  color: #666;
  background-color: #212429;
  font-size: 12px;
  line-height: 0.75rem;
  color: rgba(130, 130, 140, 0.8);
  .modal-header {
    margin-bottom: 10px;
    h2 {
      text-align: center;
      color: #fff;
      line-height: 1.3;
      padding-bottom: 10px;
    }
    p {
      border-top: 2px solid white;
      text-align: center;
      font-size: 18px;
      padding: 20px 0;
      margin: 0;
      color: #fff;
    }
  }
  .modal-body {
    width: 600px;
  }
  .form-group {
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    color: #fff;
    .add-btn {
      position: absolute;
      right: 0;
      top: 0;
      color: #cad1d6;
      border: none;
      background-color: var(--adminprimarycolor);
      font-size: 16px;
      padding: 14px;
      border-radius: 0 4px 4px 0;
      line-height: 12px;
    }
    .list-url {
      clear: both;
      width: 80%;
      line-height: 25px;
      float: right;
      font-size: 13px;
      margin-top: -6px;
      min-height: 15px;
      color: #848890;
      margin-left: 120px;
      p {
        text-align: left;
      }
      a {
        display: inline;
        color: #848890;
      }
      span {
        font-size: 10px;
        top: -6px;
        color: #ff0202;
        left: 3px;
        position: relative;
      }
    }
    label {
      width: 20%;
      display: inline-block;
      font-size: 0.875rem;
      color: #cad1d6;
      letter-spacing: 0.07em;
      float: left;
      padding: 9px;
      line-height: 20px;
    }
    input {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    select {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 14px;
      color: #cad1d6;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      text-transform: capitalize;
      cursor: pointer;
      background-color: transparent;
      border: 1px solid rgba(170, 170, 170, 0.1);
      border-radius: 4px;
    }
    input[type='checkbox'] {
      width: auto;
      background-color: #323239;
      color: #cad1d6;
      &::before,
      &::after {
        background-color: #323239;
        color: #cad1d6;
      }
    }
    textarea {
      width: 80%;
      float: left;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      height: 100px;
      resize: none;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    button {
      padding: 5px;
      font-size: 16px;
      margin-left: 10px;
      float: right;
    }
  }
}
.top-li {
  .button-box {
    top: 0;
  }
  a {
    svg {
      font-size: 19px;
      color: #ddd;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
.sub-li {
  padding: 10px 10px 10px 0;
  margin-top: 15px;
  // height: 30px;
  .button-box {
    top: -2px;
  }
  a {
    font-size: 14px;
    svg {
      font-size: 14px;
      color: #cad1d6;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
a {
  svg {
    color: #fff;
    &:hover {
      color: var(--adminprimarycolor) !important;
    }
  }
}
.com-title {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 20px;
}
.actions-btn {
  margin-bottom: 20px;
}
.close-btn {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mr-20 {
  margin-right: 20px;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--adminprimarycolor);
  border-radius: 3px;
  appearance: none;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 19px;
  line-height: 20px;
  color: var(--adminovertext);
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
.line-hr {
}
.sm {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  border-top: 1px solid var(--adminprimarycolor);
  width: 100%;
  text-align: left;
  color: #fff;
  margin-top: -20px;
}
tr.no-bg {
  line-height: 80px;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 36px 0 8px;
  }
}
.mg-tp-20 {
  margin-top: 20px;
}
tr.no-bg-no-pg {
  line-height: 40px;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 0 0 8px;
  }
}
td a {
  display: inline-block;
  margin-right: 8px;
}
.col-lh-40 {
  line-height: 40px;
  display: inline;
}
tr.no-bg-axnbtn {
  overflow: hidden;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 36px 0 8px;
  }
}
.display-only {
  display: inline-block;
  top: 5px;
  clear: none;
  margin-left: 0;
}
::v-deep input[type='text']:disabled {
  cursor: not-allowed !important;
}
::v-deep
  .form-input
  .form-input-field.v-select
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  color: #cad1d6;
  font-size: 14px;
  margin-right: 7px;
}
::v-deep .form-input-wrap .form-click .action-icons svg {
  margin-right: 10px;
}
::v-deep .form-input-wrap .form-click .action-icons svg,
::v-deep .form-input-wrap .form-click .selected-text {
  font-size: 14px;
}
::v-deep
  .form-input
  .form-input-field.v-select.vs--open
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  transform: rotate(-180deg);
  font-size: 14px;
}
::v-deep svg {
  font-size: 14px;
}
.sub-li {
  margin-left: 12px !important;
  margin-right: 30px !important;
}
.intip4 {
  position: relative;
  top: 0;
  svg {
    color: #fff;
    opacity: 0.75;
    &:hover {
      color: var(--adminprimarycolor) !important;
    }
  }
}
.intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  width: 100px;
  padding: 10px 12px;
  margin-left: -50px;
  font-size: 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #fdea85;
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
}
::v-deep .form-input-wrap {
  height: 44px;
  text-transform: capitalize !important;
  cursor: pointer !important;
  background-color: #323239 !important;
  border: 1px solid rgba(170, 170, 170, 0.1) !important;
  border-radius: 4px !important;
  color: #cad1d6 !important;
  font-size: 14px !important;
}

::v-deep .modal-body .form-input .form-input-field.v-select {
  height: 40px;
  text-transform: capitalize !important;
  cursor: pointer !important;
  background-color: #323239 !important;
  border: 1px solid rgba(170, 170, 170, 0.1) !important;
  border-radius: 4px !important;
  color: #cad1d6 !important;
  font-size: 14px !important;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #323239 !important;
}
::v-deep .form-input .form-input-field.v-select .vs__search {
  padding: 4px 6px;
  color: #cad1d6;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
::v-deep
  .form-input
  .form-input-field.v-select
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  color: #cad1d6;
  font-size: 14px;
}
::v-deep
  .form-input
  .form-input-field.v-select.vs--open
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  transform: rotate(-180deg);
  font-size: 14px;
}
// ::v-deep .v-select.vs--searchable.required.multiple-input-field.form-input-field {
//   top: -1px;
//   left: -1px;
//   width: 100.5%;
// }

::v-deep .modal-body .form-click {
  border: 1px solid rgba(170, 170, 170, 0.1) !important;
  padding: 0;
  margin: 0;
  &.disabled {
    cursor: not-allowed;
  }
}

::v-deep .accounts-container .form-input .form-input-field.v-select .vs__selected-options {
  padding: 0 6px !important;
}
::v-deep .confirm-modal-accounts .modal-wrapper-box {
  padding-bottom: 0 !important;
  display: flex !important;
  align-items: center !important;
}
::v-deep .confirm-modal-accounts .selfServBox .modal-container {
  min-height: 30px !important;
  /* min-width: 300px !important; */
  max-width: unset !important;
  min-width: unset !important;
  width: 100% !important;
  color: #a1a5af !important;
}

::v-deep .confirm-modal-accounts .modal-mask .selfServBox .modal-container .modal-footer {
  background-color: unset !important;
  text-align: center !important;
}
::v-deep .confirm-modal-accounts .modal-mask .selfServBox .modal-container .container-text {
  text-align: center;
}
::v-deep .confirm-modal-accounts .modal-mask .selfServBox {
  overflow-x: hidden !important;
}
::v-deep
  .accounts-container
  .form-input
  .form-input-field.v-select
  .vs__dropdown-toggle
  .vs__selected {
  padding: 0 6px !important;
  margin: 0 4px !important;
  text-transform: none;
}
::v-deep .accounts-container .modal-body .form-click {
  padding: 0 5px 0 0 !important;
}
::v-deep .accounts-container .action-icons {
  padding: 0 2px 0 0 !important;
  svg {
    font-size: 13px !important;
  }
}
@media screen {
  * {
    box-sizing: border-box;
  }
  .intip4 {
    position: relative;
    top: 0;
  }
  .intip4[data-tool-tip]::after {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 99;
    display: block;
    min-width: 110px;
    padding: 10px 12px;
    margin-left: -50px;
    font-size: 12px;
    line-height: 1.2em;
    text-align: center;
    pointer-events: none;
    content: attr(data-tool-tip);
    border-radius: 3px;
    transition: ease 0.3s;
    transform: scale(0);
    transform-origin: bottom center;
  }
  .intip4[data-tool-tip]::after {
    width: auto;
    margin-left: -45px;
    font-size: 12px;
    font-weight: 400;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
  }
  .intip4[data-tool-tip]:hover::after {
    bottom: 23px;
    transform: scale(1);
  }
  .list-pixel {
    .intip4[data-tool-tip]:hover::after {
      bottom: 15px;
      transform: scale(1);
    }
  }
}
.table-wrap {
  .table-scroll {
    max-height: 12.5rem;
  }
  .tbl {
    thead {
      z-index: 1;
    }
    tbody {
      max-height: unset !important;
      overflow: initial !important;
    }
  }
  th {
    color: var(--adminovertext) !important;
  }
  td {
    height: 3.5em !important;
    &:last-child {
      overflow: initial !important;
      span {
        margin-right: 10px;
      }
    }
  }
}
::v-deep .wide-btn {
  .k-btn.btn-size-3 {
    width: 145px !important;
  }
}
::v-deep .show-pixel-screen .modal-body .tbl-export .sub-row .intip4[data-tool-tip]::after {
  width: 103px !important;
  min-width: 103px !important;
}
.show-pixel-screen {
  .modal-wrapper-box {
    max-width: none !important;
    .modal-body {
      width: 650px;
      .tbl-export {
        .intip4[data-tool-tip]:hover::after {
          bottom: 20px;
        }
      }
    }
  }
}
.user-section {
  li {
    .user-blk {
      display: inline-flex;
      .user-col-lg {
        width: 236px;
        &:nth-child(2) {
          text-align: center;
          font-size: 14px;
        }
      }
    }
    .user-hd-blk {
      display: inline-flex;
      .user-hd-col-lg {
        width: 236px;
        font-size: 18px;
        color: #cad1d6;
        &:nth-child(2) {
          text-align: center;
        }
      }
    }
  }
}
.msg {
  padding: 26px;
  font-size: 20px;
  color: #69eb0d;
  letter-spacing: 0.9px;
}
</style>
