<template>
  <b-modal v-if="showModal" :header="false" header-text="Invite User" @close="onClickCancel">
    <div slot="modal-body" class="modal-wrapper-box">
      <div class="modal-container">
        <div v-if="responseMessage" class="row between-xs response-msg success-text">
          {{ responseMessage }}
        </div>
        <div v-if="error" class="error">{{ error }}</div>

        <div class="label-text">
          Enter one or more email addresses to invite. Users will be prompted to create a password
          when accepting the invite.
        </div>

        <k-text-area
          ref="emails"
          class="emails"
          :required="true"
          :value="emails"
          placeholder="Email addresses"
          @change="onChangeEmails"
        />

        <div class="label-text">Select role to assign user(s) to</div>
        <k-select
          :options="roleOptions"
          label-key="name"
          :multiple="false"
          :value="roleId"
          label="Please Select Role"
          @input="onRoleSelect"
        />
        <k-select
          name="applications"
          label-key="name"
          label="Applications"
          :required="true"
          :multiple="true"
          :fancy="true"
          :options="allApplications"
          :close-on-select="false"
          :value="userApplications"
          @input="onSelectApplication"
        />
        <k-select
          :options="allOrganizations"
          label-key="name"
          :multiple="false"
          :value="organizationId"
          :searchable="true"
          label="Please select Organization"
          @input="onSelectOrganization"
        />
        <br />
        <k-button :size="4" label="Invite User(s)" @click="onClickInvite" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { get, sync } from 'vuex-pathify';

import { User } from 'adready-api/model/user';
import { isBlank, parseValidEmails } from 'adready-api/helpers/common';
import usersApi, { userRolesApi } from 'adready-api/api/user';
import KSelect from 'adready-vue/components/elements/k-select.vue';
import KTextArea from 'adready-vue/components/elements/k-text-area.vue';
import forklift from '~/components/mixins/forklift-mixin';
import authzMixin from '~/components/mixins/authz-mixin';

export default {
  name: 'InviteUser',
  components: {
    BModal: () => import(/* webpackChunkName: "b-modal" */ '~/components/elements/b-modal.vue'),
    KSelect,
    KTextArea,
  },
  mixins: [forklift, authzMixin],
  props: {
    showModal: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      roleId: null,
      emails: '',
      responseMessage: null,
      errorMsg: null,
      userApplications: [],
      organizationId: null,
    };
  },

  computed: {
    currentAccountId: sync('common/currentAccountId'),
    currentUser: get('common/currentUser'),
    allApplications: get('common/allApplications'),
    allUsers: get('common/allUsers'),
    allRoles: get('common/allRoles'),
    allOrganizations: get('common/allOrganizations'),
    roleOptions() {
      const { canCreateRole } = this.currentUser.role;
      return this.allRoles.filter((r) => canCreateRole.includes(r.name));
    },
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      return null;
    },
  },
  mounted() {
    this.loadAllUsers();
    this.loadRoleMasters();
    this.loadApplications();
    this.loadAllOrganizations();
  },
  methods: {
    onSelectOrganization(organizationId) {
      this.organizationId = organizationId;
    },
    onSelectApplication(obj) {
      this.userApplications = obj;
    },
    userExists(email) {
      return !!this.allUsers.find((u) => u.email === email);
    },

    onClickCancel(ev) {
      this.$emit('onClickCancel', ev);
    },

    onChangeEmails(val) {
      this.emails = val;
    },

    onRoleSelect(val) {
      this.roleId = val;
    },

    onClickInvite() {
      this.errorMsg = null;

      const [out, emails] = parseValidEmails(this.emails);
      if (isBlank(emails)) {
        this.errorMsg = 'Please enter one or more valid emails';
        this.$refs.emails.focus();
        return;
      }

      this.emails = out; // update with clean list output
      const apps = this.userApplications.map((o) => {
        return { applicationId: o.id };
      });
      // create user objs
      const users = emails.map((email) => {
        return new User({
          email,
          provider: 'LOCAL',
          enabled: false,
          userApplications: !isBlank(apps) ? apps : [],
          organizationId: this.organizationId,
        });
      });

      // validate that they don't already exist
      for (let i = 0; i < users.length; i++) {
        const user = users[i];
        if (this.userExists(user.email)) {
          this.errorMsg = `User already exists with email ${user.email}`;
          this.$refs.emails.focus();
          return;
        }
      }

      const promises = [];
      const rolePromises = [];

      users.forEach((user) => {
        promises.push(
          usersApi.create(user).then((updatedUser) => {
            if (!isBlank(this.roleId)) {
              rolePromises.push(
                userRolesApi
                  .createGlobalRoles([updatedUser.id, this.roleId], {
                    userId: updatedUser.id,
                    roleId: this.roleId,
                  })
                  .then(() => {
                    usersApi.sendInvite(updatedUser.id);
                  })
              );
            } else {
              usersApi.sendInvite(updatedUser.id);
            }
            return updatedUser;
          })
        );
      });

      // reload data once all users and roles created
      Promise.all(promises).then(() => {
        Promise.all(rolePromises).then(() => {
          this.reloadUsersData();
          this.responseMessage = 'Users invited successfully';
          this.$emit('close');
        });
      });
    },

    reloadUsersData() {
      this.loadAllUsers(true);
      this.loadAccountsData(this.currentAccountId, true);
    },
  },
};
</script>

<style lang="scss" scoped>
/**custom */
.list-box {
  position: relative;
  z-index: 2;
  color: #fff;
  background-color: #212429;
  border-radius: 0;
  padding: 30px 20px;
  width: 100%;
  display: block;
  min-width: 800px;
  max-width: 800px;
  margin: 0 auto;
  ul {
    margin-bottom: 10px;
    ul {
      border: 0;
      margin: 10px 0 0 0;
    }
    li {
      font-size: 12px;
      line-height: 0.75rem;
      color: rgba(130, 130, 140, 0.8);
      padding-bottom: 5px;
      padding-top: 5px;
      margin-bottom: 0;
      position: relative;

      li {
        border-bottom: 1px solid rgba(170, 170, 170, 0.1);
        padding: 22px 15px 15px 10px;
        list-style: none;
        list-style-position: inside;
        font-size: 13px;
        &::first-child {
          margin-top: 20px;
          right: 20px;
        }
        a {
          color: #848890;
        }
        // .box {
        //   svg {
        //     color: #848890;
        //     &:hover {
        //       color: var(--adminprimarycolor) !important;
        //     }
        //   }
        // }

        .button-box {
          top: 10px;
          line-height: 34px;
          right: 2px;
          .box {
            svg {
              color: #848890;
              &:hover {
                color: var(--adminprimarycolor) !important;
              }
            }
          }
        }
      }
      a {
        color: #cad1d6;
        display: inline;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .button-box {
    position: absolute;
    top: -4px;
    right: 12px;
    line-height: 30px;
    .box {
      display: inline-block;
      margin-left: 10px;
    }
  }
}
.modal-wrapper-box {
  width: 100%;
  min-width: 500px;
  max-width: 650px;
  padding: 15px;
  margin: 0 auto;
  color: #666;
  background-color: #212429;
  font-size: 12px;
  line-height: 0.75rem;
  color: rgba(130, 130, 140, 0.8);
  .modal-header {
    margin-bottom: 10px;
    h2 {
      text-align: center;
      color: #fff;
      line-height: 1.3;
      padding-bottom: 10px;
    }
    p {
      border-top: 2px solid white;
      text-align: center;
      font-size: 18px;
      padding: 20px 0;
      margin: 0;
      color: #fff;
    }
  }
  .modal-body {
    width: 600px;
  }
  .form-group {
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    color: #fff;
    .add-btn {
      position: absolute;
      right: 0;
      top: 0;
      color: #cad1d6;
      border: none;
      background-color: var(--adminprimarycolor);
      font-size: 16px;
      padding: 14px;
      border-radius: 0 4px 4px 0;
      line-height: 12px;
    }
    .list-url {
      clear: both;
      width: 80%;
      line-height: 25px;
      float: right;
      font-size: 13px;
      margin-top: -6px;
      min-height: 15px;
      color: #848890;
      margin-left: 120px;
      p {
        text-align: left;
      }
      a {
        display: inline;
        color: #848890;
      }
      span {
        font-size: 10px;
        top: -6px;
        color: #ff0202;
        left: 3px;
        position: relative;
      }
    }
    label {
      width: 20%;
      display: inline-block;
      font-size: 0.875rem;
      color: #cad1d6;
      letter-spacing: 0.07em;
      float: left;
      padding: 9px;
      line-height: 20px;
    }
    input {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    select {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 14px;
      color: #cad1d6;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      text-transform: capitalize;
      cursor: pointer;
      background-color: transparent;
      border: 1px solid rgba(170, 170, 170, 0.1);
      border-radius: 4px;
    }
    input[type='checkbox'] {
      width: auto;
      background-color: #323239;
      color: #cad1d6;
      &::before,
      &::after {
        background-color: #323239;
        color: #cad1d6;
      }
    }
    textarea {
      width: 80%;
      float: left;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      height: 100px;
      resize: none;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    button {
      padding: 5px;
      font-size: 16px;
      margin-left: 10px;
      float: right;
    }
  }
}
.top-li {
  .button-box {
    top: 0;
  }
  a {
    svg {
      font-size: 19px;
      color: #ddd;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
.sub-li {
  padding: 10px 10px 10px 0;
  margin-top: 15px;
  // height: 30px;
  .button-box {
    top: -2px;
  }
  a {
    font-size: 14px;
    svg {
      font-size: 14px;
      color: #cad1d6;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
a {
  svg {
    color: #fff;
    &:hover {
      color: var(--adminprimarycolor) !important;
    }
  }
}
.com-title {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 20px;
}
.actions-btn {
  margin-bottom: 20px;
}
.mr-20 {
  margin-right: 20px;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--adminprimarycolor);
  border-radius: 3px;
  appearance: none;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 19px;
  line-height: 20px;
  color: var(--adminovertext);
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
.line-hr {
}
.sm {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  border-top: 1px solid var(--adminprimarycolor);
  padding: 10px 0 20px 0;
  width: 100%;
  text-align: left;
  color: #fff;
  margin-top: -20px;
}
tr.no-bg {
  line-height: 80px;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 36px 0 8px;
  }
}
.mg-tp-20 {
  margin-top: 20px;
}
tr.no-bg-no-pg {
  line-height: 40px;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 0 0 8px;
  }
}
td a {
  display: inline-block;
  margin-right: 8px;
}
.col-lh-40 {
  line-height: 40px;
  display: inline;
}
.label-text {
  text-align: left;
  padding: 8px 0px;
}
.success-text {
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding: 16px 0px;
}
tr.no-bg-axnbtn {
  overflow: hidden;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 36px 0 8px;
  }
}
.display-only {
  display: inline-block;
  top: 5px;
  clear: none;
  margin-left: 0;
}
</style>
